import React from 'react'



const BannerLanding = (props) => (
    <section id="bannerusefullinks" className="style2">
        <div className="inner">
            <header className="major">
                <h1>Useful links</h1>
            </header>
        </div>
    </section>
)

export default BannerLanding
